<template>
  <div class="landDetail" style="min-width: 1900px;">
    <div class="landDetail_head">
      <div class="landDetail_head_left" v-if="ImageAndVideo.length">
        <div
          class="landDetail_head_left_bg1"
          v-show="
            ImageAndVideo[ImageAndVideoIndex] &&
            ImageAndVideo[ImageAndVideoIndex].type != 'video'
          "
        >
          <img :src="ImageAndVideo[ImageAndVideoIndex].value" />
        </div>
        <div
          class="landDetail_head_left_bg"
          v-if="
            ImageAndVideo[ImageAndVideoIndex] &&
            ImageAndVideo[ImageAndVideoIndex].type == 'video'
          "
        >
          <video
            :src="ImageAndVideo[ImageAndVideoIndex].value"
            ref="videoPlayer"
            class="video-js"
          ></video>
        </div>
        <div class="landDetail_head_left_list">
          <div class="swiper-container">
            <div class="swiper-wrapper">
              <div
                class="swiper-slide landDetail_head_left_list_item"
                :class="index == ImageAndVideoIndex ? ' active' : ''"
                v-for="(item, index) in ImageAndVideo"
                :key="item.value"
                @click="changeImageAndVideoIndex(index)"
              >
                <img :src="item.value" v-if="item.type == 'image'" />
                <video :src="item.value" v-if="item.type == 'video'"></video>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="landDetail_head_right">
        <div class="landDetail_head_right_projectLandCode">
          编号{{ landInfo.projectLandCode }}
        </div>
        <div class="landDetail_head_right_content">
          <div class="landDetail_head_right_content_label">有效期：</div>
          <div class="landDetail_head_right_content_value">
            {{ landInfo.landExpireDate | fomatterDate }}
          </div>
        </div>
        <div class="landDetail_head_right_content">
          <div class="landDetail_head_right_content_label">种植日期：</div>
          <div class="landDetail_head_right_content_value">
            {{ landInfo.plantDate | fomatterDate }}
          </div>
        </div>
        <div class="landDetail_head_right_content">
          <div class="landDetail_head_right_content_label">成熟日期：</div>
          <div class="landDetail_head_right_content_value">
            {{ landInfo.matureDate | fomatterDate }}
          </div>
        </div>
        <div class="landDetail_head_right_content">
          <div class="landDetail_head_right_content_label">交割日期：</div>
          <div class="landDetail_head_right_content_value">
            {{ landInfo.deliveryDate | fomatterDate }}
          </div>
        </div>
        <div class="landDetail_head_right_content">
          <div class="landDetail_head_right_content_label">PH值：</div>
          <div class="landDetail_head_right_content_value">
            {{ landInfo.phValue }}
          </div>
        </div>
        <div class="landDetail_head_right_content">
          <div class="landDetail_head_right_content_label">海拔：</div>
          <div class="landDetail_head_right_content_value">
            {{ landInfo.elevation }}
          </div>
        </div>
        <div class="landDetail_head_right_content">
          <div class="landDetail_head_right_content_label">坡度：</div>
          <div class="landDetail_head_right_content_value">
            {{ landInfo.slope }}
          </div>
        </div>
      </div>
    </div>
    <div class="landDetail_content">
      <el-tabs type="border-card">
        <el-tab-pane
          :label="item.title"
          v-for="item in landDetail"
          :key="item.title"
        >
          <div class="landDetail_content_html" v-html="item.content"></div>
        </el-tab-pane>
      </el-tabs>
    </div>
    <div class="landDetail_other">
      <div class="landDetail_other_head">土地推荐</div>
      <div class="landDetail_other_list">
        <div
          class="landDetail_other_list_item"
          v-for="item in landList"
          :key="item.projectLandId"
        >
          <img :src="item.landFirstImage" height="240px" />
          <div class="landDetail_other_list_item_name">
            编号{{ item.projectLandCode }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Swiper } from "swiper";
import { getLandDetail, getPojectDetail } from "@/api/index.js";
export default {
  name: "landDetail",
  data() {
    return {
      landInfo: {},
      landDetail: [],
      landList: [],
      ImageAndVideo: [],
      ImageAndVideoIndex: 0,
      container: null,
      myPlayer: null,
    };
  },
  filters: {
    fomatterDate: (val) => {
      if (val.length > 11) {
        val = val.replace("-", "年");
        val = val.replace("-", "月");
        val = val.replace("-", "年");
        val = val.replace("-", "月");
        val = val.replace(",", "日 - ");
        val += "日";
      } else {
        val = val.replace("-", "年");
        val = val.replace("-", "月");
        val += "日";
      }
      return val;
    },
  },
  mounted() {
    window.scroll(0, 0);
    this.projectLandId = this.$route.query.id;
    this.projectId = this.$route.query.projectId;
    this.getPojectDetail();
    this.getLandDetail();
  },
  methods: {
    changeImageAndVideoIndex(index) {
      this.ImageAndVideoIndex = index;
      if (this.ImageAndVideo[this.ImageAndVideoIndex].type == "video") {
        this.initVideo();
      } else {
        if (this.myPlayer) {
          this.myPlayer.dispose();
        }
      }
      this.container.slideTo(
        Math.floor(this.ImageAndVideo.length / 3),
        1000,
        false
      );
    },
    initVideo() {
      this.$nextTick(() => {
        this.myPlayer = this.$video(this.$refs.videoPlayer, {
          controls: true,
          //自动播放属性,muted:静音播放
          autoplay: false,
          //建议浏览器是否应在<video>加载元素后立即开始下载视频数据。
          preload: "auto",
          //设置视频播放器的显示宽度（以像素为单位）
          // width: "800px",
          //设置视频播放器的显示高度（以像素为单位）
          // height: "400px",
          playbackRates: [0.5, 1, 1.5, 2, 3], // 倍速播放
          controlBar: {
            playToggle: true,
          },
        });
        this.myPlayer.src(this.ImageAndVideo[this.ImageAndVideoIndex].value); // 重新初始化URL
        // myPlayer.load(url); //重新加载
      });
    },
    getLandDetail() {
      getLandDetail(this.projectLandId).then((res) => {
        this.landInfo = res.data;
        const landDetail = this.landInfo.landDetail.split("|");
        this.landDetail = landDetail.map((val) => {
          return JSON.parse(val);
        });
        const landImage = this.landInfo.landImage.split(",");
        let landVideo = [];
        if (this.landInfo.landVideo) {
          landVideo = this.landInfo.landVideo.split(",");
        }
        landImage.forEach((val) => {
          this.ImageAndVideo.push({
            type: "image",
            value: val,
          });
        });
        landVideo.forEach((val) => {
          this.ImageAndVideo.push({
            type: "video",
            value: val,
          });
        });
        this.$nextTick(() => {
          if (
            this.ImageAndVideo.length &&
            this.ImageAndVideo[0].type === "video"
          ) {
            this.initVideo();
          }
          this.container = new Swiper(".swiper-container", {
            slidesPerView: 4,
            spaceBetween: 0,
          });
        });
      });
    },
    getPojectDetail() {
      getPojectDetail(this.projectId).then((res) => {
        this.landList = res.data.landDTOList;
        const index = this.landList.findIndex((val) => {
          return val.projectLandId == this.projectLandId;
        });
        this.landList.slice(index, 1);
        this.landList.forEach((val) => {
          val.landFirstImage = val.landImage.split(",")[0];
        });
      });
    },
  },
};
</script>
<style scoped lang="scss">
.landDetail {
  width: 100%;
  background: url("../../static/newImg/landDetail.png") no-repeat;
  background-size: cover;
  padding: 100px 0;

  .landDetail_head {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    background: #ffffff;
    border: 1px solid #e8e8e8;

    .landDetail_head_left {
      width: 50%;
      padding: 40px;
      height: 560px;

      .landDetail_head_left_bg,
      .landDetail_head_left_bg1 {
        width: 550px;
        height: 400px;
        position: relative;
        background: #f5f5f7;

        img,
        video {
          width: 550px !important;
          height: 400px !important;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
      }

      .landDetail_head_left_list {
        display: flex;
        margin-top: 32px;
        width: 550px;

        .landDetail_head_left_list_item {
          width: 130px !important;
          text-align: center;
          border: 3px solid #fff;
          height: 110px;
          overflow: hidden;

          img,
          video {
            max-width: 100%;
            max-height: 100%;
          }
        }

        .active {
          border: 3px solid #b32d11;
        }
      }
    }

    .landDetail_head_right {
      width: 50%;
      height: 640px;
      border-left: 1px solid #e8e8e8;

      .landDetail_head_right_projectLandCode {
        width: calc(100% - 60px);
        height: 88px;
        background: #f5f5f7;
        font-size: 28px;
        font-weight: 500;
        color: #252525;
        line-height: 88px;
        padding: 0 30px;
      }

      .landDetail_head_right_content {
        line-height: 63px;
        font-size: 24px;
        font-weight: 400;
        color: #595959;
        margin: 0 30px;
        border-bottom: 1px dashed #e8e8e8;

        .landDetail_head_right_content_label {
          width: 120px;
          display: inline-block;
        }

        .landDetail_head_right_content_value {
          font-size: 24px;
          font-weight: 500;
          color: #252525;
          display: inline-block;
        }
      }

      .landDetail_head_right_content:last-child {
        margin-bottom: 30px;
      }
    }
  }

  .landDetail_content {
    width: 1200px;
    margin: 40px auto;

    .landDetail_content_html {
      padding: 50px;
    }
  }

  .landDetail_other {
    width: 1200px;
    margin: 0 auto;

    .landDetail_other_head {
      width: 100%;
      height: 88px;
      background: #f5f5f7;
      font-size: 28px;
      font-weight: 500;
      color: #354a32;
      line-height: 88px;
      padding: 0 34px;
    }

    .landDetail_other_list {
      background-color: #fff;
      padding: 40px;
      display: flex;
      justify-content: space-between;

      .landDetail_other_list_item {
        width: 32%;
        text-align: center;
      }
    }
  }
}

::v-deep .el-tabs__item {
  height: 88px;
  line-height: 88px;
  text-align: center;
  font-size: 28px;
  font-weight: 400;
  color: #252525;
}

::v-deep .el-tabs__item.is-active {
  color: #354a32 !important;
}

::v-deep
  .el-tabs--border-card
  > .el-tabs__header
  .el-tabs__item:not(.is-disabled):hover {
  color: #354a32 !important;
}

::v-deep .video-js {
  width: 550px !important;
  height: 400px !important;
  background-color: #f5f5f7;

  .vjs-big-play-button {
    width: 100px;
    height: 100px;
    border: 0;
    background-color: rgba(0, 0, 0, 0.67);
    border-radius: 50%;
    line-height: 100px;
    font-size: 80px;
    transform: translate(-50%, -50%);
    margin: 0;
  }
}
</style>
